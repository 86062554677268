import {CommonModule, NgOptimizedImage} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {MatRippleModule} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {ActivatedRoute} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import Persona from 'persona';
import {MessagingModule} from 'projects/bd-innovations/messaging/src';
import {SubscribeForMessageAdded} from 'projects/bd-innovations/messaging/src/lib/messaging.actions';
import {SimMessagingService} from 'projects/sim/src/app/_shared/providers/sim-messaging.service';
import {IsExistEmailQuery} from 'projects/sim/src/app/settings/users/gql/is-exist-email.query';
import {IsSubAccountExistQuery} from 'projects/sim/src/app/sub-accounts/gql/is-sub-account-exist.query';
import {from, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, map, take} from 'rxjs/operators';

import {ImpersonateService, UserToken} from '../../services/impersonate/impersonate.service';
import {TranslationSettingsService} from '../../services/translation-settings.service';
import {
  KeycloakPasswordPoliciesModule,
} from '../../storybook/keycloak-password-policies/keycloak-password-policies.module';
import {
  KeycloakPasswordPoliciesService,
} from '../../storybook/keycloak-password-policies/keycloak-password-policies.service';
import {LoadingSpinnerModule} from '../../storybook/loading-spinner/loading-spinner.module';
import {FloButtonComponent} from '../elements/flo-button/flo-button.component';

import {KycSettingQuery} from './gql/kycSetting.query';
import {RegistrationMutation} from './gql/registration.mutation';
import {SubAccountsRegistrationQuery} from './gql/sub-accounts-registration.query';
import {RegistrationPopupComponent} from './registration-popup/registration-popup.component';

@Component({
  selector: 'lib-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    FloButtonComponent,
    KeycloakPasswordPoliciesModule,
    MessagingModule,
    LoadingSpinnerModule,
    MatRippleModule,
  ],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  logo: string;
  form: FormGroup;
  subAccounts: {name: string; id: string; path: string; currency: string;}[];
  hide: boolean = true;
  messaging: boolean = false;
  waiting: boolean = false;
  client: string;
  disableSignUp: boolean = true;
  skipVerificationFlag: boolean = false;
  kycCanceled: boolean = false;
  private readonly sub$: Subscription = new Subscription();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly http: HttpClient,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly impersonateService: ImpersonateService,
    private readonly passwordPoliciesValidationService: KeycloakPasswordPoliciesService,
    private readonly simMessagingService: SimMessagingService,
    private readonly translateSettings: TranslationSettingsService,
    private readonly isExistEmailQuery: IsExistEmailQuery,
    private readonly subAccountsRegistrationQuery: SubAccountsRegistrationQuery,
    private readonly isSubAccountExistQuery: IsSubAccountExistQuery,
    private readonly registrationMutation: RegistrationMutation,
    private readonly kycSettingQuery: KycSettingQuery,
  ) {}

  ngOnInit(): void {
    this.client = document.domain;
    this.translateSettings.setAndSaveLang(this.route.snapshot.queryParamMap?.get('lang') || 'en');
    this.setLogo();
    this.getSubAccounts();
    this.createForm();
    this.setMessaging();
  }

  ngOnDestroy(): void {
    this.clearing();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const country: {name: string; id: string; path: string; currency: string;} = this.form.get('country').value;

      this.waiting = true;

      this.setToken().subscribe(() => {
        this.registrationMutation.mutate({
          input: {
            accountName: this.form.get('accountName').value,
            userName: this.form.get('userName').value,
            email: this.form.get('email').value,
            password: this.form.get('password').value,
            country: country.name,
            accountParentId: country.id,
            path: country.path,
            currency: country.currency,
          },
        }).subscribe((): void => {
          this.impersonateService.unImpersonate();
        });
      });
    }
  }

  openKycVerification(): void {
    this.setToken().subscribe(() => {
      this.kycSettingQuery.fetch()
        .pipe(
          map(({data}) => data.kycSettings),
          catchError(error => {
            console.error('Please configure kycTemplateId and kycEnvId', error);

            return from([]);
          }))
        .subscribe(data => {
          if (data.templateId && data.environmentId) {
            const client = new Persona.Client({
              templateId: data.templateId,
              referenceId: this.form.get('email').value,
              environmentId: data.environmentId,
              fields: {
                nameFirst: this.form.get('userName').value,
                emailAddress: this.form.get('email').value,
              },
              onReady: () => {
                client.open();
              },
              onComplete: () => {
                this.disableSignUp = false;
                this.cdr.detectChanges();
              },
              onCancel: () => {
                this.kycCanceled = true;
                this.cdr.detectChanges();
              },
              onError: error => {
                console.error(error);
              },
            });
          }
          this.impersonateService.unImpersonate();
        });
    });
  }

  skipVerification(): void {
    this.skipVerificationFlag = true;
    this.disableSignUp = false;
  }

  isExistEmail(): void {
    this.setToken().subscribe(() => {
      this.isExistEmailQuery.fetch({email: this.form.get('email').value})
        .subscribe(isExist => {
          const emailIsExist = (isExist?.data as unknown as {isExistEmail: boolean;})?.isExistEmail;

          if (this.form.get('email').errors && !this.form.get('email').errors.exist) {
            return;
          }
          this.form.get('email').setErrors(emailIsExist ? {exist: true} : null);
          this.cdr.detectChanges();
          this.impersonateService.unImpersonate();
        });
    });
  }

  isExistAccount(): void {
    this.setToken().subscribe(() => {
      this.isSubAccountExistQuery.fetch({
        accountId: this.form.get('country').value?.id,
        name: this.form.get('accountName').value,
      })
        .subscribe(isExist => {
          const accountIsExist = (isExist?.data as unknown as {isSubAccountExists: boolean;})?.isSubAccountExists;

          this.impersonateService.unImpersonate();
          if (this.form.get('accountName').errors && !this.form.get('accountName').errors.exist) {
            return;
          }
          this.form.get('accountName').setErrors(accountIsExist ? {exist: true} : null);
          this.cdr.detectChanges();
        });
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      accountName: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9 (),._-]+$/)]],
      country: ['', Validators.required],
      userName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, RegistrationComponent.emailDomainValidator()]],
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required],
    }, {
      validators: [
        this.validateMatchingPasswords('password', 'confirmPassword'),
      ],
    });

    this.setToken().subscribe(() => {
      this.sub$.add(this.passwordPoliciesValidationService.setPolicies(this.form.get('password'), this.form.get('email'))
        .subscribe(() => {
          this.impersonateService.unImpersonate();
        }));
    });
  }

  private validateMatchingPasswords(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: FormGroup): ValidatorFn => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      matchingControl.setErrors(control.value !== matchingControl.value ? {mustMatch: true} : null);
    };
  }

  private getSubAccounts(): void {
    this.setToken().subscribe(() => {
      this.subAccountsRegistrationQuery.fetch({client: this.client})
        .subscribe(accounts => {
          this.subAccounts = (accounts.data as unknown as {
            subAccountsRegistration: {id: string; name: string; path: string; currency;}[];
          })?.subAccountsRegistration;
          this.form.get('country').setValue(this.subAccounts?.[0]);
          this.impersonateService.unImpersonate();
        });
    });
  }

  private setMessaging(): void {
    this.setToken().subscribe(() => {
      this.store.dispatch(new SubscribeForMessageAdded());
      this.messaging = true;
      this.cdr.detectChanges();
    });

    this.sub$.add(this.simMessagingService.messageAdded$.pipe(
      filter(res => (res.status === 'SUCCESSFULLY_FINISHED' || res.status === 'FINISHED_WITH_ERRORS') && res.translationParams.includes('REGISTRATION')),
    ).subscribe(res => {
      this.waiting = false;
      this.cdr.detectChanges();

      if (res.status === 'SUCCESSFULLY_FINISHED') {
        this.dialog.open(RegistrationPopupComponent, {width: '580px'}).afterClosed().subscribe(() => {
          this.clearing();
          window.location.assign(window.location.origin);
        });
      }
    }));
  }

  private clearing(): void {
    this.impersonateService.unImpersonate();
    this.sub$.unsubscribe();
  }

  private setLogo(): void {
    this.http.get(`file-storage/file-names-list/login/logo/${this.client}`)
      .pipe(take(1), catchError(() => of([])))
      .subscribe((files: string[]) => {
        this.logo = files?.length ? `file-storage/download-cors/login/logo/${files[0]}` : '../../assets/images/flo-logo.svg';
      });
  }

  private setToken(): Observable<void> {
    return this.http.get('/registration/grant-token')
      .pipe(
        take(1),
        catchError(() => {
          this.impersonateService.unImpersonate();

          return null;
        }),
        filter((token: UserToken) => !!token?.access_token),
        map((token: UserToken) => {
          this.impersonateService.setTemporaryToken(token.access_token);
        }),
      );
  }

  private static emailDomainValidator(): ValidatorFn {
    const domains: string[] = ['flolive', 'vodafone'];

    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;

      if (!value) {
        return null;
      }

      return domains.reduce((includes: boolean, domain: string) => includes || value.includes(`@${domain}.`), false) ?
        null : {domainError: true};
    };
  }
}
